import { User, cloneUser } from '@aa/models/user';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, merge, of } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { CLIENT_ID, CLIENT_SECRET } from './authentication.module';

export const KEY_USER = 'cached_user';
export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_ACCESS_TOKEN_EXPIRATION = 'access_token_expiration';
export const KEY_REFRESH_TOKEN = 'refresh_token';

export interface OauthRequest {
  client_id: string;
  client_secret: string;
  grant_type: string;
  token?: string;
  refresh_token?: string;
  username?: string;
  password?: string;
}

export interface AuthResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

@Injectable()
export class AuthenticationService {
  private loggedInUserSource = new ReplaySubject<User | null>(1);
  private isLoggedInSource = new ReplaySubject<boolean>(1);
  private requestLoginSource = new Subject<void>();
  private requestLogoutSource = new Subject<void>();
  private requestLoginCancelledSource = new Subject<void>();
  private isAuthenticating = new BehaviorSubject<boolean>(false);

  private refreshingSubject = new BehaviorSubject<boolean>(false);
  private refreshing = false;

  loggedInUser$: Observable<User | null> = this.loggedInUserSource.asObservable().pipe(debounceTime(33), shareReplay());
  isLoggedIn$ = this.isLoggedInSource.asObservable().pipe(debounceTime(33), shareReplay(), distinctUntilChanged());
  requestLogin$ = this.requestLoginSource.asObservable();
  requestLogout$ = this.requestLogoutSource.asObservable();

  userSnapshot: User | null;
  loggedInSnapshot: boolean;

  private refreshTimeout: any;

  private _access_token: string;
  private _refresh_token: string;
  private _expires: string;

  private authenticationRequiredSubscription = Subscription.EMPTY;

  tryAccessToken(): Observable<string | null> {
    return this.isAuthenticating.asObservable().pipe(
      filter((value) => !value),
      take(1),
      map(() => {
        try {
          return this._access_token || window.localStorage.getItem(KEY_ACCESS_TOKEN);
        } catch (_) {
          // ignore
        }
        return null;
      }),
    );
  }

  get refreshToken(): string {
    try {
      return this._refresh_token || window.localStorage.getItem(KEY_REFRESH_TOKEN);
    } catch (_) {
      // ignore
    }
    return null;
  }

  private get expiration(): number {
    try {
      return +(this._expires || localStorage.getItem(KEY_ACCESS_TOKEN_EXPIRATION));
    } catch (e) {
      return 0;
    }
  }

  constructor(
    @Inject(CLIENT_ID) private clientId: string,
    @Inject(CLIENT_SECRET) private clientSecret: string,
    private http: HttpClient,
  ) {
    let userJson: any;
    try {
      userJson = window.localStorage.getItem(KEY_USER);
    } catch (e) { }

    this.isAuthenticating.next(false);

    if (userJson) {
      this.setUser(JSON.parse(userJson) as User);
      this.createRefreshTimeout();
    } else {
      this.setUser(null);
      this.isLoggedInSource.next(false);
    }

    this.isAuthenticating.next(false);
  }

  isLoggedIn(): boolean {
    let result = false;
    try {
      const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN);
      result = !!(accessToken);
    } catch (e) {
      console.error(e);
    }
    return result;
    // return this.expiration > Math.floor(Date.now() / 1000);
  }

  isAuthorized(): boolean {
    return this.isLoggedIn();
  }

  isExpired(): boolean {
    try {
      return (
        (this._access_token || localStorage.getItem(KEY_ACCESS_TOKEN)) &&
        this.expiration <= Math.floor(Date.now() / 1000)
      );
    } catch (e) {
      return true;
    }
  }

  canRefresh(): boolean {
    try {
      return !!(this._refresh_token || localStorage.getItem(KEY_REFRESH_TOKEN));
    } catch (e) {
      return false;
    }
  }

  authenticationRequired(callback: () => void, attemptRefresh: boolean = true) {
    if (this.loggedInSnapshot) {
      callback();
    } else if (attemptRefresh && this.isExpired() && this.canRefresh()) {
      this.refresh().subscribe({
        next: (success) => {
          if (success) {
            callback();
          } else {
            this.authenticationRequired(callback, false);
          }
        },
        error: (error) => {
          console.error(error);
          this.authenticationRequired(callback, false);
        }
      });
    } else {
      this.authenticationRequiredSubscription.unsubscribe();
      this.authenticationRequiredSubscription = this.isLoggedInSource
        .asObservable()
        .pipe(filter((isLoggedIn) => isLoggedIn), take(1))
        .subscribe(() => callback());
      this.requestLogin();
    }
  }

  requireUser(): Observable<User | null> {
    let result: Observable<User | null>;

    const user = this.userSnapshot;
    if (user) {
      result = of(user);
    } else {
      // attempt to refresh if it's an option, otherwise just return false (not logged in)
      const refresh: Observable<boolean> = (this.isExpired() && this.canRefresh()) ? this.refresh() : of(false);

      result = refresh.pipe(
        switchMap((loggedIn) => {
          if (loggedIn) {
            // if we're logged in, then we're good to go
            return of(true);
          } else {
            this.requestLogin();

            // otherwise, listen for logins and cancellations and merge them taking the first event
            const loggedIn = this.loggedInUserSource.asObservable()
              .pipe(filter((user) => !!user));
            const cancelled = this.requestLoginCancelledSource.asObservable()
              .pipe(map(() => null));
            return merge(loggedIn, cancelled).pipe(take(1));
          }
        }),
      );
    }

    return result;
  }

  thirdPartyLogin(type: string, token: string): Observable<HttpResponse<AuthResponse>> {
    return this.getAccessToken({
      token,
      grant_type: type,
    });
  }

  login(username: string, password: string): Observable<HttpResponse<AuthResponse>> {
    return this.getAccessToken({
      username,
      password,
      grant_type: 'password',
    });
  }

  private logout() {
    this._refresh_token = null;
    this._access_token = null;
    this._expires = null;
    try {
      localStorage.clear();
    } catch (e) {
      console.error(e);
    }

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    Sentry.setUser(null);
  }

  refresh(): Observable<boolean> {
    if (this.refreshing) {
      return this.refreshingSubject.pipe(
        filter((refreshing) => !refreshing),
        take(1),
        map(() => true)
      );
    } else {
      this.refreshing = true;
      this.refreshingSubject.next(true);

      const refresh_token = this.refreshToken;
      if (refresh_token) {
        return this.getAccessToken({
          refresh_token,
          grant_type: 'refresh_token',
        }).pipe(
          map(() => {
            this.refreshing = false;
            this.refreshingSubject.next(false);

            return true;
          })
        );
      } else {
        return of(false);
      }
    }
  }

  requestLogin() {
    this.requestLoginSource.next();
  }

  requestLoginCancelled() {
    this.requestLoginCancelledSource.next();
    this.authenticationRequiredSubscription.unsubscribe();
  }

  requestLogout() {
    this.requestLogoutSource.next();

    this.logout();
    this.setUser(null);
  }

  setUser(user: User | null) {
    this.userSnapshot = cloneUser(user);
    if (user) {
      const userJson = JSON.stringify(this.userSnapshot);
      try {
        localStorage.setItem(KEY_USER, userJson);
      } catch (e) { }
    }
    this.loggedInUserSource.next(this.userSnapshot);
    this.loggedInSnapshot = user !== null;
    this.isLoggedInSource.next(this.loggedInSnapshot);
  }

  private getAccessToken(params): Observable<HttpResponse<AuthResponse>> {
    if (params['grant_type'] !== 'refresh_token') {
      const refuid = localStorage.getItem('refuid');
      if (refuid) {
        params['refuid'] = refuid;
      }
    }
    params.client_id = this.clientId;
    params.client_secret = this.clientSecret;
    this.isAuthenticating.next(true);
    return this.http.post<AuthResponse>('/oauth2/token', params, { observe: 'response' }).pipe(
      tap((response) => {
        if (!!response.body.access_token) {
          this.saveAccessToken(response.body);
        }
      }),
      catchError((err) => {
        return of(null).pipe(
          delay(500), // wait a moment to avoid race conditions
          map(_ => {
            this.resetFromLocalStorage();

            if (!this.isExpired() && this.canRefresh()) {
              this.isAuthenticating.next(false);
              this.createRefreshTimeout();
              return null;
            } else {
              console.error(err);
              this.requestLogout();

              throw err;
            }
          })
        )
      })
    );
  }

  private resetFromLocalStorage() {
    this._access_token = window.localStorage.getItem(KEY_ACCESS_TOKEN);
    this._refresh_token = window.localStorage.getItem(KEY_REFRESH_TOKEN);
    this._expires = window.localStorage.getItem(KEY_ACCESS_TOKEN_EXPIRATION);
  }

  private saveAccessToken(auth: AuthResponse) {
    this._access_token = auth.access_token;
    this._refresh_token = auth.refresh_token;
    const expiration = Math.floor(Date.now() / 1000) + auth.expires_in;
    this._expires = `${expiration}`;
    try {
      window.localStorage.setItem(KEY_ACCESS_TOKEN, this._access_token);
      window.localStorage.setItem(KEY_REFRESH_TOKEN, this._refresh_token);
      window.localStorage.setItem(KEY_ACCESS_TOKEN_EXPIRATION, this._expires);
    } catch (e) {
      // ignore
    }
    this.isAuthenticating.next(false);
    this.createRefreshTimeout();
  }

  private createRefreshTimeout() {
    clearTimeout(this.refreshTimeout);

    const timeout = this.expiration * 1000 - new Date().getTime();
    this.refreshTimeout = setTimeout(
      () => this.refresh().pipe(take(1)).subscribe(),
      timeout
    );
  }

  private buildHidePlaceTripLogDialogKey(userId: number): string {
    return `auth:${userId}:hidePlaceTripLogDialogKey`;
  }

  adminRequestHidePlaceTripLogDialog(hide: boolean): boolean {
    const user = this.userSnapshot;
    if (!user) return false;
    if (user.account_type != 'ADMIN' && user.account_type != 'TRIP_VIEWER' && user.account_type != 'FRONTEND_ADMIN') return false;

    try {
      const key = this.buildHidePlaceTripLogDialogKey(user.id);
      if (hide) {
        window.localStorage.setItem(key, '1');
      } else {
        window.localStorage.removeItem(key);
      }
      return true;
    } catch (_) {
      return false;
    }
  }

  adminHidePlaceTripLogDialog(): boolean {
    const user = this.userSnapshot;
    if (!user) return false;
    if (user.account_type != 'ADMIN' && user.account_type != 'TRIP_VIEWER' && user.account_type != 'FRONTEND_ADMIN') return false;

    try {
      const key = this.buildHidePlaceTripLogDialogKey(user.id);
      return window.localStorage.getItem(key) != null;
    } catch (_) {
      return false;
    }
  }
}
